<template>
<div class="administrationreviews-container">
    <slideout-panel></slideout-panel>


    <vs-table v-model="selected" style="height: 80vh; width: 100%; margin-top: 20px;">
        <template #thead>
            <vs-tr>
                <vs-th style="width: 20%;">
                    Utente
                </vs-th>
                <vs-th>
                    POI
                </vs-th>
                <vs-th>
                    Testo
                </vs-th>
                <vs-th>
                    Stelle
                </vs-th>
                <vs-th>
                    Immagini
                </vs-th>
                <vs-th>
                    Ultima modifica
                </vs-th>
            </vs-tr>
        </template>
        <template #tbody>
            <vs-tr :key="i" v-for="(tr, i) in reviews" :data="tr" :is-selected="selected == tr">
                <vs-td>
                    {{ tr.user.name }}
                    {{ tr.user.surname }}
                </vs-td>
                <vs-td>
                    {{ tr.poi.localized != null && user !== null ? tr.poi.localized.find(e => e.language_id == user.language.id).name : '-' }}
                </vs-td>
                <vs-td>
                    {{ tr.description }}
                </vs-td>
                <vs-td>
                    <Stars :stars="tr.rating" />
                </vs-td>
                <vs-td>
                    <div v-if="tr.pictures != null" class="images">
                        <img :src="baseUri + '/reviews/' + tr.id + '/pictures/' + picture.id" v-for="picture in tr.pictures" :key="'picture-'+picture.id" />
                    </div>
                </vs-td>

                <vs-td>
                    {{ (new Date(tr.updated_at)).toLocaleString() }}
                </vs-td>
            </vs-tr>
        </template>
        <template #footer>
            <vs-row justify="center" align="center">
                <vs-button :disabled="result == null || result.before == null" @click="previousPage">
                    <img src="../../assets/icons/previous_page.png" />
                </vs-button>
                <vs-button :disabled="result == null || result.after == null" @click="nextPage">
                    <img src="../../assets/icons/next_page.png" />
                </vs-button>
            </vs-row>

        </template>
    </vs-table>
</div>
</template>

    
<script>
import {
    apiCall,
    baseUri
} from '../../utils/ApiMiddleware';
import EditReview from '../../components/elements/EditReview';
import Stars from '../../components/Stars.vue';

export default ({
    data() {
        return {
            reviews: [],
            selected: null,
            filters: {},
            result: null,
            panel: null,
            baseUri
        }
    },

    components: {
        Stars,
    },

    props: {
        user: {
            type: Object,
            required: false
        }
    },

    mounted() {
        this.getReviews(this.filters)
    },

    methods: {
        previousPage() {
            this.filters.before = this.result.before;
            this.filters.after = null;
            this.filters.__ob__.dep.notify();
        },

        nextPage() {
            this.filters.after = this.result.after;
            this.filters.before = null;
            this.filters.__ob__.dep.notify();
        },

        async getReviews(p) {
            this.selected = null;
            const loading = this.$vs.loading();
            const response = await apiCall('GET', '/backoffice/reviews', p);
            loading.close();
            // if status code is 200, show a success message 
            if (response.status == 200) {
                console.log(response.data.data)
                this.reviews = response.data.data;
                this.result = response.data;
            } else if (response.status != 0) {
                this.$vs.notification({
                    title: this.$t('common.messages.somethingWentWrong'),
                    text: 'Impossibile caricare le recensioni',
                    color: 'danger',
                    position: 'top-right'
                });
            }

        },
    },

    watch: {
        filters(n) {
            this.getReviews(n);
        },
        async selected(n) {
            
            console.log(n)
            var vm = this;

            if (this.panel != null) {
                await this.panel.hide();
                this.panel = null;
            }

            if (n == null) {
                return;
            }
            this.panel = await this.$showPanel({
                component: EditReview,
                openOn: 'right',
                props: {
                    review: n,
                    user: this.user,
                    deleted: () => {
                        this.getReviews()
                    }
                }
            });
            console.log(this.panel)

            this.panel.promise
                .then(() => {
                    vm.selected = null;
                });

        }
    },
})
</script>

    
<style scoped>
.administrationreviews-container {
    padding-left: 70px;
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
}

.administrationreviews-container .images {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}

.administrationreviews-container .images img {
    max-height: 40px;
    max-width: 40px;
    width: auto;
    border-radius: 5px;
}
</style>
